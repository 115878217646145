<template>
  <div>
    <b-row>
      <b-col>
        <h3>過去の天気情報</h3>
      </b-col>
    </b-row>
    <b-modal ref="dialog" ok-only>{{this.errorFieldMsg}}</b-modal>
    <b-row>
      <b-col sm="3">
        <label>畑を選択</label>
        <field-form @getField="setField"></field-form>
      </b-col>
      <b-col sm="4">
        <label>開始日指定</label>
        <b-form-input
          type="date"
          v-model="startDate"
          @change="selectStartDate"
          :state="this.errorDate.status"
          aria-describedby="input-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">{{this.errorDate.message}}</b-form-invalid-feedback>
      </b-col>
      <b-col sm="1">
        <p style="margin-top: 35px;">から</p>
      </b-col>
      <b-col sm="4">
        <label>終了日付指定</label>
        <b-form-input
          type="date"
          v-model="endDate"
          @change="selectEndDate"
          :state="this.errorDate.status"
          aria-describedby="input-live-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback">{{this.errorDate.message}}</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="1">
        <b-button type="submit" size="sm" variant="primary" v-on:click="getWeatherObservation">登録</b-button>
      </b-col>
      <b-col sm="2">
        <b-spinner v-if="create_status == status.register" variant="primary" label="Spinning"></b-spinner>
        <p class="my-1" v-if="create_status == status.done">登録完了</p>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import FieldForm from "./form/FieldForm";
import { CREATE_WEATHER_OBSERVATION } from "../../components/weather-observation-mutation";
import moment from "moment";

const STATUS_DONE = 1;
const STATUS_REGISTER = 2;
const ONE_YEAR = 365;
const MILLISECOND_DAY = 86400000;

export default {
  components: {
    FieldForm
  },
  data() {
    return {
      weatherObservations: {},
      startDate: "",
      endDate: "",
      field: null,
      create_status: null,
      status: {
        done: STATUS_DONE,
        register: STATUS_REGISTER
      },
      errorDate: { status: null, message: "" },
      errorFieldMsg: null
    };
  },
  created() {
    this.init();
  },
  watch: {
    startDate: function() {}
  },
  methods: {
    selectStartDate: function() {
      let startDate = new Date(this.startDate);
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      if (yesterday - startDate < 0) {
        this.startDate = moment(yesterday).format("YYYY-MM-DD");
        return;
      }
    },
    selectEndDate: function() {
      let endDate = new Date(this.endDate);
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);

      if (yesterday - endDate < 0) {
        this.endDate = moment(yesterday).format("YYYY-MM-DD");
        return;
      }
    },
    init: function() {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      this.startDate = this.endDate = moment(yesterday).format("YYYY-MM-DD");
    },
    setField: function(value) {
      this.field = value;
    },
    validateCheck: function() {
      let startDate = new Date(this.startDate);
      let endDate = new Date(this.endDate);
      let date_difference = (endDate - startDate) / MILLISECOND_DAY;

      if (!this.field.lat || !this.field.lon) {
        this.errorFieldMsg = "畑に位置情報が記載されていません";
        this.$refs["dialog"].show();
        return false;
      }

      if (date_difference > ONE_YEAR || date_difference < ONE_YEAR * -1) {
        this.errorDate.message = "最大登録日数は365日までです。";
        this.errorDate.status = false;
        return false;
      }

      if (date_difference < 0) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.errorDate.status = true;
      return true;
    },
    getWeatherObservation: function() {
      if (!this.validateCheck()) {
        return;
      }
      this.create_status = STATUS_REGISTER;
      this.$apollo
        .mutate({
          mutation: CREATE_WEATHER_OBSERVATION,
          variables: {
            startDate: this.startDate,
            endDate: this.endDate,
            fieldId: this.field.id
          }
        })
        .then(result => {
          this.errorDate.status = null;
          this.create_status = STATUS_DONE;
        })
        .catch(error => {
          alert(error);
        });
    }
  }
};
</script>
