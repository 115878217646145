<template>
  <div>
    <b-card>
      <weather-observation />
    </b-card>
  </div>
</template>
<script>
import WeatherObservation from "../components/WeatherObservation";

export default {
  components: {
    WeatherObservation
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
