import gql from 'graphql-tag'

export const CREATE_WEATHER_OBSERVATION = gql`
  mutation($fieldId:ID,$startDate:Date,$endDate:Date){
    createWeatherObservation(fieldId:$fieldId,startDate:$startDate,endDate:$endDate){
      weatherObservation{
        id
        date
        time
        temperature
        precipitation
        windDirection
        windSpeed
        atmosphericPressure
        relativeHumidity
        sunshineDuration
      }
    }
  }
`
